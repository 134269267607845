//
// AppScreen
//
// The core app screen, with a header and workspace.
//
// The workspace is added by the specific screen from where we are in the app.
//
// The header has an aboutbox, a link to the account screen (if logged in), and a
// workspace switcher for the active project (if any).
//
// For development purposes, the header also has a link to the Lab workspace.

import React, { useEffect } from 'react'
import { useStore } from '../stores'
import { observer } from 'mobx-react-lite'
import { DateTime } from 'luxon'

import { VView, HView, Spacer, Divider, LayerView, Layer } from '../appview'
import { useTooltipPopover } from '../hooks/useTooltipPopover'
import { useDebounce } from '../hooks/useDebounce'
import {
    AboutPanel,
    MessagePanel,
    Avatar,
    ProjectIcon,
    ProjectSelectionPanel,
} from '../panels'
import { BackupMenu } from '../menus'
import { Bar, Button, Icon, Toggle } from '../components'

const LastBackup = observer(function LastBackup() {
    const store = useStore()
    const app = store.app
    return store.lastbackup === null || store.ticker === null
        ? ''
        : app.text('Last backup {lastbackup}', {
              lastbackup: DateTime.fromJSDate(store.lastbackup).toRelative(),
          })
})

const ActivityButton = observer(function ActivityButton() {
    const store = useStore()
    const app = store.app
    const debouncedActivity = useDebounce(store.api ? store.api.open_requests : 0, 500)

    return debouncedActivity ? (
        <LayerView>
            <Bar raised className="bg-info">
                <Button icon title={app.text('Activity')}>
                    <Icon name={'settings'} size={2} spin />
                </Button>
            </Bar>
            <Layer className="badge" anchor="top-end" edge>
                {debouncedActivity}
            </Layer>
        </LayerView>
    ) : null
})

export const AppScreen = observer(function AppScreen({ project, user, children }) {
    const store = useStore()
    const app = store.app

    const AboutPopover = useTooltipPopover(AboutPanel)
    const ProjectSelectionPopover = useTooltipPopover(ProjectSelectionPanel)
    const BackupMenuPopover = useTooltipPopover(BackupMenu)
    const MessagePopover = useTooltipPopover(MessagePanel, {
        preferredPosition: 'below',
        neverHide: true,
        hideArrow: true,
    })

    let pageTitle = app.text('Unicat')

    const AboutButton = (
        <>
            <AboutPopover.Panel version={store.version} />
            <div ref={AboutPopover.anchorRef}>
                <Bar raised className={AboutPopover.isOpen() ? 'cc-selected' : ''}>
                    <Button
                        icon
                        title={app.text('About Unicat')}
                        onClick={AboutPopover.onShow}
                    >
                        <LayerView style={{ width: 24, height: 24 }}>
                            <Layer anchor="center">
                                <Icon name="unicat" size={4} />
                            </Layer>
                        </LayerView>
                    </Button>
                </Bar>
            </div>
        </>
    )

    let AccountButton
    let InfoBar
    let DebugToggle
    let LabButton

    if (user) {
        pageTitle = app.text('{username} - Unicat', { username: user.name })

        AccountButton = (
            <Avatar
                user={user}
                selected={store.view.workspace === 'account'}
                onClick={e => store.view.setWorkspace('account')}
            />
        )

        if (project) {
            pageTitle = app.text('{projectname} - Unicat', {
                projectname: project.name,
            })

            const WorkspaceButtons = Object.keys(store.view.workspaces)
                .filter(key => key !== 'account')
                .map(key => {
                    const workspace = store.view.workspaces[key]
                    return (
                        <Button
                            key={key}
                            active={store.view.workspace === key}
                            disabled={project.status !== 'active'}
                            title={workspace.title}
                            onClick={e => store.view.setWorkspace(key)}
                        >
                            <Icon
                                name={workspace.icon}
                                label={workspace.title}
                                size={2}
                            />
                            <span
                                style={{
                                    paddingLeft: 3,
                                    position: 'relative',
                                    top: -7,
                                }}
                            >
                                {workspace.label}
                            </span>
                        </Button>
                    )
                })

            InfoBar = (
                <>
                    {store.message ? (
                        <MessagePopover.Panel message={store.message} />
                    ) : undefined}
                    <div ref={MessagePopover.anchorRef}>
                        <Bar
                            raised
                            className={
                                [
                                    'settings',
                                    'schema',
                                    'pim',
                                    'dam',
                                    'importexport',
                                ].includes(store.view.workspace)
                                    ? 'cc-selected'
                                    : ''
                            }
                        >
                            <HView className="info">
                                <ProjectSelectionPopover.Panel
                                    user={user}
                                    project={project}
                                    dismiss={ProjectSelectionPopover.hide}
                                />
                                <HView
                                    vcenter
                                    gap={8}
                                    className="project-name"
                                    ref={ProjectSelectionPopover.anchorRef}
                                    onClick={ProjectSelectionPopover.onShow}
                                >
                                    <ProjectIcon project={project} />
                                    <div>{store.project.name}</div>
                                </HView>
                                <Bar>{WorkspaceButtons}</Bar>
                                <div className="last-saved">
                                    <LastBackup />
                                </div>
                                <BackupMenuPopover.Panel
                                    dismiss={BackupMenuPopover.hide}
                                />
                                <div ref={BackupMenuPopover.anchorRef}>
                                    <Button
                                        icon
                                        title={app.text('Backup and restore')}
                                        disabled={project.status !== 'active'}
                                        onClick={BackupMenuPopover.onShow}
                                    >
                                        <Icon
                                            name={'backup'}
                                            label={app.text('Backup and restore')}
                                            size={2}
                                        />
                                    </Button>
                                </div>
                            </HView>
                        </Bar>
                    </div>
                </>
            )

            DebugToggle = (
                <Bar>
                    <Button icon disabled title={app.text('Toggle DEBUG info')}>
                        <Toggle
                            onChange={value => {
                                store.setDEBUG(value)
                            }}
                            value={store.DEBUG}
                            style={{ position: 'relative', top: '5px' }}
                        />
                    </Button>
                </Bar>
            )

            LabButton = (
                <Bar
                    raised
                    className={store.view.workspace === 'lab' ? 'cc-selected' : ''}
                >
                    <Button
                        icon
                        title={app.text('Lab experiments')}
                        disabled={project.status !== 'active'}
                        active={store.view.workspace === 'lab'}
                        onClick={e => store.view.setWorkspace('lab')}
                    >
                        <Icon name={'lab'} label={app.text('Lab')} size={2} />
                    </Button>
                </Bar>
            )
        }
    }

    useEffect(() => {
        document.title = pageTitle
    }, [pageTitle])

    const [triggerError, setTriggerError] = React.useState(false)
    if (triggerError) {
        throw new Error('💥')
    }
    const Kaboom = (
        <Bar>
            <Button
                title={app.text('trigger error')}
                onClick={() => {
                    setTriggerError(true)
                }}
            >
                <LayerView style={{ width: 24, height: 24 }}>
                    <Layer anchor="center">🧨</Layer>
                </LayerView>
            </Button>
        </Bar>
    )

    return (
        <VView>
            <HView className="header">
                <HView grow>{AboutButton}</HView>
                {InfoBar}
                <HView grow>
                    {InfoBar ? <Spacer size={5} /> : undefined}
                    <ActivityButton />
                    <Spacer />
                    {store.can('debug') ? DebugToggle : undefined}
                    {store.can('dev') && store.DEBUG ? LabButton : undefined}
                    {store.can('dev') && store.DEBUG ? Kaboom : undefined}
                    {AccountButton ? <Spacer size={5} /> : undefined}
                    {AccountButton}
                </HView>
            </HView>

            <Divider />

            {children}
        </VView>
    )
})
