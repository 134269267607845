//
// Store
//
// The Store is the gateway to all things data. We have server data, such as pim
// records, assets, users, projects, etc. These are fetched using the .api
// UnicatApi, and stored in the .data DataStore. Then we have user interface
// texts, like buttons and tooltips and other copy, which are available through the
// .text TextStore so we can localize later. The state of the user interface, which
// user is logged in, the project they are working on, the current workspace and
// environmet, and other related data (like what is shown in the pim tree view
// navigation) is held in the .view ViewStore. Simple things like the width of sidebars
// or scrollpositions are stored as state in the ui components themselves, using the
// usePersistentState hook and a userkey or projectkey prefix so we can store this
// separately for multiple users/projects. The prefix userkey or projectkey (which
// incorporates the userkey) is available from the Store.
//
// The Store serves as the Source of Truth.

import { Settings } from 'luxon'

import { makeObservable, observable, computed, action, reaction } from 'mobx'
import JWT from './api/JWT'
import UnicatApi from './api/UnicatApi'
import UnicatDam from './api/UnicatDam'
import CCDamImageserver from './imageservers/CCDamImageserver'

import DataStore from './data'
import ViewStore from './view'
import AppStore from './app'
import { maybe_gid_regex } from '../utils/gid'

class Store {
    version = '2024.12.001'
    DEBUG = false
    _deployment = null
    _testdatabase_gid = '84a1b5e4-fb5d-4ad6-a497-a3cf5eaf29b1'

    // location is taken from the URL (window.location)
    // we can be in one of five places:
    // - the marketing site   WELCOME
    // - the sign up form     SIGNUP
    // - the login form       LOGIN (also: forgot password)
    // - the project switcher ACCOUNT + account settings
    // - the active project   PROJECT  + project-gid [ + invite]
    // a location can have associated info
    // - WELCOME: where in the site: path (other (exact) location paths can't be in WELCOME)
    // - SIGNUP: no extra info
    // - LOGIN: no extra info
    // - ACCOUNT: no extra info
    // - PROJECT: project gid, optional invite
    // a location has a base endpoint
    // - WELCOME: /
    // - SIGNUP: /signup
    // - LOGIN: /login
    // - INVITE: /invite
    // - ACCOUNT: /p
    // - PROJECT: /p/<gid>
    location = null
    location_info = null
    LOCATIONS = {
        WELCOME: 'welcome',
        SIGNUP: 'signup',
        LOGIN: 'login',
        INVITE: 'invite',
        ACCOUNT: 'account',
        PROJECT: 'project',
    }

    jwt = null
    api = null
    dam = null
    ccimageserver = null
    data = null
    view = null
    app = null
    appLanguage = null
    ticker = 0
    lastbackup = null
    message = null

    is_loading = true
    is_busy = false

    constructor() {
        makeObservable(this, {
            DEBUG: observable,
            setDEBUG: action.bound,
            version: observable,
            setVersion: action.bound,
            lastbackup: observable,
            message: observable,
            setMessage: action.bound,
            clearMessage: action.bound,
            ticker: observable,
            tick: action.bound,
            isLoggedIn: computed,
            hasLoginToken: computed,
            user: computed,
            userkey: computed,
            project: computed,
            projectkey: computed,
            language: computed,
            imageserver: computed,
            init: action.bound,
            appLanguage: observable,
            setAppLanguage: action.bound,
            handleInitialResult: action.bound,
            login: action.bound,
            logout: action.bound,
            is_loading: observable,
            is_busy: observable,

            app: observable,
            data: observable,
            jwt: observable,
            api: observable,
            dam: observable,
            ccimageserver: observable,
            view: observable,

            location: observable,
            location_info: observable,

            signupRequest: action.bound,
            signup: action.bound,
            resetPasswordRequest: action.bound,
            resetPassword: action.bound,
        })

        this.parseLocation()

        const projectgid =
            this.location_info && this.location_info['projectgid']
                ? this.location_info['projectgid']
                : null

        // order of setup is important here

        this.app = new AppStore()
        this.data = new DataStore(this)

        this.jwt = new JWT('_jwt')
        this.api = new UnicatApi(
            '//' + window.location.host + '/api',
            projectgid,
            this.jwt,
            this
        )
        this.dam = new UnicatDam(
            '//' + window.location.host + '/dam',
            projectgid,
            this.jwt
        )
        this.ccimageserver = new CCDamImageserver({}, this)

        this.view = new ViewStore(this)

        reaction(
            () => this.jwt.token,
            token => {
                if (!token) {
                    this.view.resetUser()
                    if (
                        [this.LOCATIONS.ACCOUNT, this.LOCATIONS.PROJECT].includes(
                            this.location
                        )
                    ) {
                        this.setLocation(this.LOCATIONS.WELCOME)
                    }
                }
            }
        )

        reaction(
            () => this.appLanguage,
            appLanguage => {
                if (this.setAppLanguage(appLanguage)) {
                    document.documentElement.setAttribute('lang', appLanguage)
                }
            }
        )

        this.appLanguage = 'en'

        this.init()
    }

    parseLocation() {
        const maybe_gid_regex_substring = '(' + maybe_gid_regex + ')'
        let match
        let pathname = window.location.pathname.endsWith('/')
            ? window.location.pathname.slice(0, -1)
            : window.location.pathname
        // sign up
        if (pathname === '/signup') {
            this.location_info = null
            this.location = this.LOCATIONS.SIGNUP
        }
        // log in
        else if (pathname === '/login') {
            this.location_info = null
            this.location = this.LOCATIONS.LOGIN
        }
        // invite
        else if (pathname.startsWith('/invite/')) {
            const maybe_invite_gids_regex = new RegExp(
                '^/invite/' +
                    maybe_gid_regex_substring +
                    '/' +
                    maybe_gid_regex_substring +
                    '$'
            )
            match = pathname.match(maybe_invite_gids_regex)
            if (match) {
                this.location_info = { usergid: match[1], projectgid: match[2] }
            } else {
                this.location_info = { usergid: 'invalid', projectgid: 'invalid' }
            }
            this.location = this.LOCATIONS.INVITE
        }
        // account
        else if (pathname === '/p') {
            this.location_info = null
            this.location = this.LOCATIONS.ACCOUNT
        }
        // project
        else if (pathname.startsWith('/p/')) {
            const maybe_project_gid_path_regex = new RegExp(
                '^/p/' + maybe_gid_regex_substring + '$'
            )
            match = pathname.match(maybe_project_gid_path_regex)
            if (match) {
                this.location_info = { projectgid: match[1] }
                this.location = this.LOCATIONS.PROJECT
            } else {
                this.setLocation(this.LOCATIONS.ACCOUNT)
                return
            }
        }
        // no location, so WELCOME
        else if (!pathname) {
            this.location_info = { path: pathname }
            this.location = this.LOCATIONS.WELCOME
        }
        // if there's a pathname that couldn't be parsed
        // we redirect to WELCOME to get rid of it
        else {
            this.setLocation(this.LOCATIONS.WELCOME)
            return
        }
    }

    setLocation(location, location_info_string = '') {
        switch (location) {
            case this.LOCATIONS.SIGNUP:
                window.location.href = '//' + window.location.host + '/signup'
                break
            case this.LOCATIONS.LOGIN:
                window.location.href = '//' + window.location.host + '/login'
                break
            case this.LOCATIONS.INVITE:
                window.location.href = '//' + window.location.host + '/invite'
                break
            case this.LOCATIONS.ACCOUNT:
                window.location.href = '//' + window.location.host + '/p'
                break
            case this.LOCATIONS.PROJECT:
                window.location.href =
                    '//' + window.location.host + '/p/' + location_info_string
                break
            case this.LOCATIONS.WELCOME:
            default:
                window.location.href =
                    '//' + window.location.host + '/' + location_info_string
                break
        }
    }

    autoLocation() {
        if (!this.user) {
            this.setLocation(this.LOCATIONS.LOGIN)
        } else if (!this.project) {
            this.setLocation(this.LOCATIONS.ACCOUNT)
        } else {
            this.setLocation(this.LOCATIONS.PROJECT, this.project.gid)
        }
    }

    setVersion = version => {
        this.version = version
    }

    setDEBUG = toggle => {
        this.DEBUG = toggle
        this.view.savePerUser('/DebugInfo', this.DEBUG)
    }

    setMessage = message => {
        this.message = message
    }

    clearMessage = () => {
        this.message = null
    }

    init = () => {
        this.setAppLanguage(this.appLanguage)
        if (this.jwt.hasToken) {
            return this._fetch('/init')
                .then(result => {
                    if (
                        this.location === this.LOCATIONS.PROJECT &&
                        (!(
                            this.location_info['projectgid'] in result['user_projects']
                        ) ||
                            !('project' in result))
                    ) {
                        this.setLocation(this.LOCATIONS.ACCOUNT)
                        return
                    }
                    this.handleInitialResult(result)
                    this.api.start_sync()
                    this.DEBUG = this.view.loadPerUser('/DebugInfo', this.DEBUG)
                    this.is_loading = false
                    return result
                })
                .catch(error => {
                    this.is_loading = false
                })
        } else {
            this.is_loading = false
        }
    }

    login = credentials => {
        return this.api
            .globalfetch('/login', credentials)
            .catch(error => {
                if (error.message === '401 Unauthorized') {
                    this.logout()
                } else {
                    console.log(
                        'ERROR',
                        '/login',
                        credentials &&
                            JSON.parse(JSON.stringify(credentials['username'])),
                        error
                    )
                    throw new Error(error) // if we don't throw, we'll just continue with the next then()
                }
            })
            .then(result => {
                if (
                    this.location === this.LOCATIONS.PROJECT &&
                    !(this.location_info['projectgid'] in result['user_projects'])
                ) {
                    this.setLocation(this.LOCATIONS.ACCOUNT)
                    return
                }
                this.handleInitialResult(result)
                return true
            })
    }

    signupRequest = (username, password, email) => {
        return this._fetch('/signup_request', { username, password, email })
    }

    signup = (username, password, email, validation_code, verification_code) => {
        return this._fetch('/signup', {
            username,
            password,
            email,
            validation_code,
            verification_code,
        }).then(result => {
            if (
                this.location === this.LOCATIONS.PROJECT &&
                !(this.location_info['projectgid'] in result['user_projects'])
            ) {
                this.setLocation(this.LOCATIONS.ACCOUNT)
                return
            }
            this.handleInitialResult(result)
            return true
        })
    }

    resetPasswordRequest = username_or_email => {
        return this._fetch('/reset_password_request', { username_or_email })
    }

    resetPassword = (username, new_password, validation_code, verification_code) => {
        return this._fetch('/reset_password', {
            username,
            new_password,
            validation_code,
            verification_code,
        }).then(result => {
            if (
                this.location === this.LOCATIONS.PROJECT &&
                !(this.location_info['projectgid'] in result['user_projects'])
            ) {
                this.setLocation(this.LOCATIONS.ACCOUNT)
                return
            }
            this.handleInitialResult(result)
            return true
        })
    }

    deleteAccount = confirm => {
        return this._globalfetch('/account/delete', { confirm }).then(result => {
            this.jwt.invalidateToken()
            this.setLocation(this.LOCATIONS.WELCOME)
        })
    }

    initInvite = (user_gid, project_gid) => {
        return this._globalfetch('/init_invite', {
            invitee: user_gid,
            project: project_gid,
        })
    }

    get isLoggedIn() {
        return this.view.isLoggedIn
    }

    get hasLoginToken() {
        return this.jwt.hasToken
    }

    logout = () => {
        this.jwt.invalidateToken()
    }

    setAppLanguage = newAppLanguage => {
        if (this.app.applanguages.has(newAppLanguage)) {
            // TODO: CC-206 load new app texts based on new app language
            // TODO: CC-206 import locale dynamically??
            // change locale
            Settings.defaultLocale = newAppLanguage
            return true
        }
        return false
    }

    _fetch(endpoint, data, additional_fetch_options) {
        if (this.location === this.LOCATIONS.PROJECT) {
            return this.api
                .fetch(endpoint, data, additional_fetch_options)
                .catch(error => {
                    if (error.message === '401 Unauthorized') {
                        this.logout()
                    } else if (error.message === '403 Forbidden') {
                        this.setLocation(this.LOCATIONS.ACCOUNT)
                    } else if (error.message === '422 Unprocessable Entity') {
                        // do nothing
                        throw new Error(error) // if we don't throw, we'll just continue with the next then()
                    } else {
                        console.log(
                            'ERROR',
                            endpoint,
                            data && JSON.parse(JSON.stringify(data)),
                            error
                        )
                        throw new Error(error) // if we don't throw, we'll just continue with the next then()
                    }
                })
        } else {
            return this._globalfetch(endpoint, data, additional_fetch_options)
        }
    }

    _globalfetch(endpoint, data, additional_fetch_options) {
        return this.api
            .globalfetch(endpoint, data, additional_fetch_options)
            .catch(error => {
                if (error.message === '401 Unauthorized') {
                    this.logout()
                    this.setLocation(this.LOCATIONS.ACCOUNT)
                } else if (error.message === '422 Unprocessable Entity') {
                    // do nothing
                    throw new Error(error) // if we don't throw, we'll just continue with the next then()
                } else {
                    console.log(
                        'ERROR',
                        endpoint,
                        data && JSON.parse(JSON.stringify(data)),
                        error
                    )
                    throw new Error(error) // if we don't throw, we'll just continue with the next then()
                }
            })
    }

    handleInitialResult = result => {
        if ('last_backed_up' in result && result['last_backed_up']) {
            this.lastbackup = new Date(result['last_backed_up'] * 1000)
        }
        if ('cc.version' in result) {
            this.setVersion(result['cc.version'])
        }
        if ('cc.cursor' in result) {
            this.api._cc_cursor = result['cc.cursor']
        }
        if ('cursor' in result) {
            this.api._cursor = result['cursor']
        }
        // set user - will lazily populate the rest from localstorage
        this.view.initWithUsergid(
            result['user'],
            'project' in result ? result['project'] : null
        )
        if ('deployment' in result) {
            this._deployment = result['deployment']
        }
    }

    tick = () => {
        this.ticker = this.ticker + 1
    }

    get user() {
        return this.view.user
    }

    get userkey() {
        return this.view.userkey
    }

    get project() {
        return this.view.project
    }

    get projectkey() {
        return this.view.projectkey
    }

    get imageserver() {
        return this.view.project._imageserver
    }

    get language() {
        return this.view.language
    }

    reportError = (error, errorinfo) => {
        const projectkey = this.projectkey
        const load = key => this.view.load(key)
        const state = this._localStorageKeys()
            .filter(key => key.startsWith(projectkey))
            .reduce((result, key) => {
                result[key.slice(projectkey.length)] = load(key)
                return result
            }, {})
        const errorReport = {
            version: this.version,
            project: this.project && this.project.gid,
            user: this.user && this.user.gid,
            error: {
                message: error.message,
                file: error.fileName,
                line: error.lineNumber,
                column: error.columnNumber,
                stack: error.stack.split('\n').filter(entry => entry.length > 0),
                componentstack: errorinfo.componentStack
                    .split('\n')
                    .filter(entry => entry.length > 0),
                state,
            },
        }
        return this._globalfetch('/errors/report', errorReport)
    }

    _localStorageKeys = () => {
        let keys = []
        for (var i = 0; i < window.localStorage.length; i++) {
            keys.push(window.localStorage.key(i))
        }
        return keys
    }

    clearProjectState = () => {
        const projectkey = this.projectkey
        this._localStorageKeys()
            .filter(key => key.startsWith(projectkey))
            .forEach(key => window.localStorage.removeItem(key))
    }

    clearAllProjectsState = () => {
        const userkey = this.userkey
        this._localStorageKeys()
            .filter(key => key.startsWith(userkey))
            .forEach(key => window.localStorage.removeItem(key))
    }

    clearEverything = () => {
        window.localStorage.clear()
    }

    can = action => {
        const roles =
            this.jwt && this.jwt.data && this.project
                ? this.jwt.data.projects[this.project.gid]
                : []
        switch (action) {
            case 'dev':
                return roles && roles.includes('dev')
            case 'debug':
                return roles && roles.includes('owner')
            case 'dev.notes':
            case 'account.signup':
                return this._deployment ? true : false
            case 'project.create':
            case 'project.delete':
                const user_roles = this.user && this.user.options.get('roles')
                if (user_roles && user_roles.includes('superuser')) {
                    return true
                }
                return this._deployment ? true : false
            default:
                return false
        }
    }
}

export default Store
