//
// UploadErrorMessage
//
// Asset upload has limits, if we get an error we show the limits

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'

import { VView, HView, Spacer } from '../appview'
import { Header, Text, Bar, Button } from '../components'

export const UploadErrorMessage = observer(function UploadErrorMessage() {
    const store = useStore()
    const { app } = store
    return (
        <VView className="message error-message upload-error-message">
            <Header className="cc-danger">
                {app.text('Upload failed, file too large?')}
            </Header>
            <Text style={{ marginBottom: 10 }}>
                {app.text(
                    'Max 10MB, 25 megapixels, or 8192 pixels for width or height'
                )}
            </Text>
            <HView>
                <Spacer />
                <Bar raised>
                    <Button
                        action
                        onClick={() => store.clearMessage()}
                        style={{ paddingLeft: 15, paddingRight: 15 }}
                    >
                        {app.text('Ok')}
                    </Button>
                </Bar>
                <Spacer />
            </HView>
        </VView>
    )
})
