//
// MessagePanel
//
// Show a (global) message

import React from 'react'
import { observer } from 'mobx-react-lite'

import { VView, HView } from '../appview'

export const MessagePanel = observer(function MessagePanel({ message }) {
    if (!message) {
        return null
    }

    return (
        <HView className="panel message-panel">
            <VView grow>{message}</VView>
        </HView>
    )
})
