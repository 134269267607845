//
// TreeNavigator
//
// A Tree view for DAM asset hierarchy

import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../stores'
import { useMousePopover } from '../../hooks/useMousePopover'

import { LazyVScrollList, LazyScrollItem } from '../../appview'
import { TreeItem, Icon } from '../../components'
import { maxChars } from '../../utils/text'

import { DragDropAssetTreeItem } from '../../dragdrop/DragDropAssetTreeItem'
import { DragTypes } from '../../dragdrop/DragTypes'
import { DeletedTreeAssetMenu, TreeAssetMenu, AssetsContextmenu } from '../../menus'
import { keyboard } from '../../utils/keyboard'
import { UploadErrorMessage } from '../../panels'

export const TreeNavigator = observer(function TreeNavigator({
    tree,
    firstindex: initialfirstindex,
    windowsize: initialwindowsize,
}) {
    const store = useStore()
    const { app, view, data } = store

    const itemheight = 19
    const [firstindex, setFirstindex] = useState(initialfirstindex)
    const [windowsize, setWindowsize] = useState(initialwindowsize)

    const onUpdate = (newfirstindex, newwindowsize) => {
        if (newfirstindex !== firstindex || newwindowsize !== windowsize) {
            tree.refetch(newfirstindex, newwindowsize).then(() => {
                setFirstindex(newfirstindex)
                setWindowsize(newwindowsize)
            })
        }
    }

    const totalsize = tree._totalsize
    const selectedAsset = tree.selectedItem ? tree.selectedItem.item : null

    // ContextMenus
    const TreeAssetContextMenu = useMousePopover(TreeAssetMenu)
    const DeletedTreeAssetContextMenu = useMousePopover(DeletedTreeAssetMenu)
    const AssetsContextmenuPopover = useMousePopover(AssetsContextmenu)

    const onDrop = (dragitem, dropitem, dropzone) => {
        const dropgid = dropitem.item ? dropitem.item.gid : dropitem.gid
        if (dragitem.hasOwnProperty('files')) {
            dragitem.files.forEach(file => {
                if (!file.type) {
                    console.log(file.name + ' - folder upload not supported')
                    return false
                }
                data.actions.assets
                    .upload(file, dropgid)
                    .then(newassetgid => {
                        tree.expandItem(dropitem)
                        view.damworksheet.refetch()
                        return newassetgid
                    })
                    .catch(error => {
                        store.setMessage(<UploadErrorMessage />)
                    })
            })
        } else if (dragitem.type === DragTypes.DAM_ASSET) {
            const dropitemproxy = dropitem.item
                ? dropitem
                : { id: dropitem.pathname, gid: dropitem.gid, item: dropitem }
            tree.expandItem(dropitemproxy).then(() => {
                data.actions.assets
                    .move(dragitem.gid, dropgid)
                    .then(() => {
                        tree.refetch().then(() => {
                            const reselectAsset = data.assets.get(dragitem.gid)
                            if (reselectAsset) {
                                tree.setSelectedId(reselectAsset.pathname)
                                tree.selectItem(tree.selectedItem) // this is a hack
                            }
                        })
                        view.damworksheet.refetch()
                    })
                    .catch(error => {})
            })
        } else if (dragitem.type === DragTypes.ASSET_SELECTION) {
            const dropitemproxy = dropitem.item
                ? dropitem
                : { id: dropitem.pathname, gid: dropitem.gid, item: dropitem }
            tree.expandItem(dropitemproxy).then(() => {
                data.actions.assets
                    .bulkMove(dragitem.selection, dropgid)
                    .catch(error => {})
            })
        }
    }

    const onCreateAsset = () => {
        tree.createItem(tree.selectedItem)
    }

    const onUploaded = newgid => {
        if (!selectedAsset) return
        tree.expandItem({ id: selectedAsset.pathname })
        view.damworksheet.refetch()
    }

    const onDownload = () => {
        if (!selectedAsset) return
        selectedAsset.download()
    }

    const onDelete = () => {
        if (!selectedAsset) return
        selectedAsset.delete()
    }

    const onUndelete = () => {
        if (!selectedAsset) return
        selectedAsset.undelete()
    }

    const onPermanentDelete = () => {
        if (!selectedAsset) return
        const parentasset = view.damworksheet.path[view.damworksheet.path.length - 2]
        selectedAsset.permanentDelete().then(successdata => {
            view.damworksheet.setAsset(parentasset)
        })
    }

    const onDeleteAssetsSelection = () => {
        if (!tree.multiselection.size) return
        data.actions.assets.bulkDelete(tree.actionSelection)
    }

    const onUndeleteAssetsSelection = () => {
        if (!tree.multiselection.size) return
        data.actions.assets.bulkUndelete(tree.actionSelection)
    }

    const onPermanentDeleteAssetsSelection = () => {
        if (!tree.multiselection.size) return
        data.actions.assets.bulkPermanentDelete(tree.actionSelection)
    }

    const onTreeAssetAction = (action, p1) => {
        const actions = {
            create_asset: onCreateAsset,
            uploaded: onUploaded,
            download: onDownload,
            delete: onDelete,
            undelete: onUndelete,
            permanent_delete: onPermanentDelete,
            delete_assets_selection: onDeleteAssetsSelection,
            undelete_assets_selection: onUndeleteAssetsSelection,
            permanent_delete_assets_selection: onPermanentDeleteAssetsSelection,
        }
        if (!(action in actions)) {
            console.log(
                `onTreeAssetAction: unhandled action '${action}' on '${tree.selectedItem.item.title}'`
            )
            return
        }
        TreeAssetContextMenu.hide()
        DeletedTreeAssetContextMenu.hide()
        AssetsContextmenuPopover.hide()
        actions[action](p1)
    }

    const onClickTreeItem = (event, treeitem) => {
        if (keyboard.testCommand(event)) {
            if (tree.multiselection.isSelected(treeitem.item.gid)) {
                tree.multiselection.deselect(treeitem.item.gid)
            } else {
                tree.multiselection.addRangeStart(treeitem.item.gid)
            }
        } else if (keyboard.testShift(event)) {
            tree.multiselection.rangeEnd(treeitem.item.gid)
        } else {
            tree.multiselection.rangeStart(treeitem.item.gid)
            tree.selectItem(treeitem)
            if (!tree.multiselection.isSelected(treeitem.item.gid)) {
                tree.multiselection.rangeStart(treeitem.item.gid)
            }
        }
    }

    const TreeItems = tree.treeitems.map((treeitem, index) => {
        const name = treeitem.item.publicname
            ? treeitem.item.publicname
            : app.text('[empty]')
        let classes = 'status-' + treeitem.item.status
        if (tree.multiselection.isSelected(treeitem.item.gid) && !treeitem.isSelected) {
            classes += ' cc-multiselected'
        }
        let selected = treeitem.isSelected
        if (
            tree.multiselection.size > 0 &&
            selected &&
            !tree.multiselection.isSelected(treeitem.item.gid)
        ) {
            selected = false
        }
        const contextmenu =
            tree.multiselection.size > 1 &&
            tree.multiselection.isSelected(treeitem.item.gid)
                ? AssetsContextmenuPopover
                : treeitem.item.status !== 'deleted'
                ? TreeAssetContextMenu
                : DeletedTreeAssetContextMenu

        return (
            <LazyScrollItem key={treeitem.item.gid} index={index + firstindex}>
                <DragDropAssetTreeItem tree={tree} treeitem={treeitem} onDrop={onDrop}>
                    <TreeItem
                        title={name}
                        state={treeitem.state}
                        emptyicon={'empty'}
                        selected={selected}
                        indent={treeitem.depth}
                        className={classes}
                        onClick={event => onClickTreeItem(event, treeitem)}
                        onExpand={() => {
                            tree.expandItem(treeitem)
                        }}
                        onCollapse={() => {
                            tree.collapseItem(treeitem)
                        }}
                        onContextMenu={e => {
                            if (!tree.multiselection.isSelected(treeitem.item.gid)) {
                                tree.multiselection.deselectAll()
                                tree.selectItem(treeitem)
                            }
                            contextmenu.onShow(e)
                        }}
                    >
                        <Icon
                            className="itemtype"
                            name={treeitem.item.iconname}
                            size={'text'}
                        />
                        {maxChars(name, 40)}
                    </TreeItem>
                </DragDropAssetTreeItem>
            </LazyScrollItem>
        )
    })

    return (
        <>
            <AssetsContextmenuPopover.Panel
                asset={selectedAsset}
                assetsselection={tree.multiselection}
                onAction={onTreeAssetAction}
            />
            <TreeAssetContextMenu.Panel
                asset={selectedAsset}
                onAction={onTreeAssetAction}
            />
            <DeletedTreeAssetContextMenu.Panel
                asset={selectedAsset}
                onAction={onTreeAssetAction}
            />
            <LazyVScrollList
                className="cc-Tree"
                firstindex={firstindex}
                itemheight={itemheight}
                totalitems={totalsize}
                onUpdate={onUpdate}
            >
                {TreeItems}
            </LazyVScrollList>
        </>
    )
})
